/*

Theme: Quick – Website UI Kit
Version: 1.1.1
Product Page: https://themes.getbootstrap.com/product/quick-website-ui-kit-2/
License: https://themes.getbootstrap.com/licenses/
Author: Webpixels
Author URI: https://webpixels.io

---

Copyright 2020 Webpixels

*/
